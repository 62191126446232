<template>
    <Page
        watchForScroll
        @reachedBottom="handleScroll"
    >
        <div class="grid grid-cols-12 gap-6 sm:pt-4">
            <CurrentBalance />
            <LeaseOverview />
        </div>
        <div v-if="resident">
            <div class="mt-8 flex">
                <span class="text-base uppercase font-bold font-ffdin text-gray-700 tracking-normal">
                    {{ $t('payments.components.account_summury') }}
                </span>
                <PeriodFilter
                    :phaseStartDate="getOccupancyData?.phaseStartDate"
                    @periodRange="handlePeriodSelection"
                />
            </div>
            <AccountSummary
                :totals="totals"
                :selectedPeriod="selectedPeriod"
                :load-more="loadMore"
                class="mt-8"
            />
        </div>
    </Page>
</template>

<script>
import AccountSummary from '@/components/payments/AccountSummary.vue';
import CurrentBalance from '@/components/dashboard/CurrentBalance.vue';
import LeaseOverview from '@/components/dashboard/LeaseOverview.vue';
import Page from '@/components/ui/Page';
import PeriodFilter from '@/components/payments/PeriodFilter';
import PaymentsMixin from '@/mixins/PaymentsMixin';

export default {
    components: { Page, AccountSummary, CurrentBalance, LeaseOverview, PeriodFilter },

    mixins: [PaymentsMixin],

    computed: {
        resident() {
            return this.currentProfile?.type === 'resident';
        },
    },

    created() {
        this.getTotals();
    },
};
</script>
