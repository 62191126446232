<template>
    <div
        class="absolute md:inherit top-20 left-0 right-0 m-auto z-20 col-span-12 lg:col-span-5 wrapper rounded-xl bg-gradient-pink flex flex-col justify-between"
    >
        <div class="flex flex-wrap">
            <div class="relative w-full pr-0 md:pr-4 max-w-full flex-grow flex-1">
                <span
                    class="price font-bold text-white"
                    data-test="price"
                >
                    {{ balance }}
                </span>
                <div class="font-medium price_desc text-white uppercase">{{ $t('payments.current_balance') }}</div>
            </div>

            <div class="relative w-auto flex-initial flex flex-col justify-end items-center text-center">
                <div class="text-white text-center inline-flex items-center justify-center">
                    <icon
                        name="calendar"
                        class="mt-2 sm:w-6 sm:h-6 block cursor-pointer"
                    />
                </div>
                <label class="currentDate block font-ffdin font-light mt-3 uppercase text-white">{{ currentDate }}</label>
            </div>
        </div>

        <div
            v-if="!isNsfLimitReached"
            class="flex mt-7"
        >
            <button
                type="button"
                class="btn rounded-full text-white border border-white uppercase font-normal w-full lg:w-auto"
                data-test="make-payment"
                @click="makePayment"
            >
                <icon
                    name="dollar"
                    class="sm:w-5 sm:h-5 mr-2 block cursor-pointer"
                />
                {{ $t('payments.pay_now') }}
            </button>
        </div>
        <div
            v-else
            class="font-medium price_desc text-white uppercase"
            data-test="pay-not-allowed"
        >
            {{ $t('payments.pay_now_is_not_allowed_description') }}
        </div>
    </div>
</template>

<script>
import { currentDate } from '@/utils/Date.js';
import Icon from '@/components/ui/Icon';
import NotifyMixin from '@/mixins/NotifyMixin';
import AuthMixin from '@/mixins/AuthMixin';
import AccountingQueryManager from '@/api/accounting/accountingQueryManager';
import AccountingMixin from '@/mixins/AccountingMixin';
import { mapActions, mapGetters } from 'vuex';
import { mask } from '@/utils/Amount';

export default {
    name: 'CurrentBalance',

    components: {
        Icon,
    },

    mixins: [AuthMixin, NotifyMixin, AccountingQueryManager, AccountingMixin],

    data() {
        return {
            currentDate,
        };
    },

    computed: {
        ...mapGetters({
            chargeTotals: 'accounting/chargeTotals',
            currentProfile: 'auth/currentProfile',
        }),

        balance() {
            return mask(this.chargeTotals ? this.chargeTotals.balance : 0, { prefix: '$' });
        },
    },

    watch: {
        currentProfile: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.getChargeTotals();
                }
            },
        },
    },

    async created() {
        if (this.currentProfile) {
            await this.getAvailablePaymentMethods();
        }
    },

    methods: {
        ...mapActions({
            setChargeTotals: 'accounting/setChargeTotals',
        }),

        async getChargeTotals() {
            try {
                const data = await this.queryHandler('getChargeTotals', 'resident', {
                    accountId: this.currentProfile.occupancyId,
                });
                this.setChargeTotals(data);
            } catch (error) {
                this.notifyError(error.message);
            }
        },

        makePayment() {
            this.$router.push({ name: 'payments.create' });
        },
    },
};
</script>

<style scoped>
.wrapper {
    padding: 1.688rem 1.563rem 1.563rem 1.563rem;
    width: 90%;
}
.currentDate {
    letter-spacing: 0.016rem;
    font-size: 0.813rem;
    line-height: 1rem;
}
.price {
    font-size: 2rem;
    line-height: 3.125rem;
    letter-spacing: 0;
}
.price_desc {
    font-size: 0.813rem;
    line-height: 1rem;
    letter-spacing: 0.061rem;
    margin-top: 0.313rem;
}
@media (min-width: 640px) {
    .price {
        font-size: 2.25rem;
    }
}
@media (min-width: 768px) {
    .md\:inherit {
        position: inherit;
    }
    .wrapper {
        width: 100%;
    }
    .price {
        font-size: 2.625rem;
    }
}
</style>
